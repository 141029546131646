<template>
  <v-container fluid class="pr-0 pl-0">
    <v-card>
      <v-card-text class="pa-0 elevation-0">
        <v-toolbar flat color="transparent">
          <v-icon class="grey--text mr-3">mdi-web</v-icon>
          <h2 class="heading font-weight-normal grey--text">{{ $t('referral.title') }}</h2>
        </v-toolbar>
        <v-divider class="mx-5"></v-divider>
        <v-card flat tile>
          <v-card-text v-if="application.state.user.referralProgramEnabled">
            <span class="subtitle-1 font-weight-light">{{ $t('referral.url') }}</span>
            <v-icon style="float: right">mdi-console-network-outline</v-icon>
            <v-text-field
              class="mt-3 mb-5"
              flat
              dense
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-link-variant"
              :value="getReferralUrl"
              readonly
            ></v-text-field>
          </v-card-text>
          <span v-else style="text-align: left; display: block; padding: 20px 0 20px 20px">
            The Referral Program has been disabled for your account. Please contact your account
            manager.
          </span>
        </v-card>
        <v-divider class="mx-5"></v-divider>
        <v-card flat tile>
          <v-card-text>
            <span class="subtitle-1 font-weight-light">{{ $t('referral.list') }}</span>
            <v-icon style="float: right">mdi-account-supervisor-outline</v-icon>
            <data-table
              :headers="headers1"
              :items="state.referralList"
              :loading="state.loading"
              :server-items-length="state.total1"
              :options.sync="state.pagination1"
              @paginate="actions.applyOptions1($event)"
            >
              <template v-slot:item._referredAffiliate="{ item }">
                <v-chip label small>{{ item._referredAffiliate }}</v-chip>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip
                  small
                  label
                  class="ma-1"
                  :color="{ INACTIVE: 'grey', ACTIVE: 'green' }[item.status]"
                  dark
                >
                  {{ item.status }}
                </v-chip>
              </template>
              <template v-slot:item.commission="{ item }">
                <v-chip label small>{{ item.commission }}%</v-chip>
              </template>
              <template v-slot:item.commisionPeriod="{ item }">
                <v-chip label small>{{ item.commisionPeriod.replace('_', ' ') }}</v-chip>
              </template>
              <template v-slot:item.createdAt="{ item }">
                <v-chip label small>{{ item.createdAt | date }}</v-chip>
              </template>
            </data-table>
          </v-card-text>
        </v-card>
        <v-divider class="mx-5"></v-divider>
        <v-card flat tile>
          <v-card-text>
            <span class="subtitle-1 font-weight-light">{{ $t('referral.commissions') }}</span>
            <v-icon style="float: right">mdi-account-cash-outline</v-icon>
            <div class="mt-3 mb-5" style="max-width: 220px">
              <span class="subtitle-2">{{ $t('referral.dateRange') }}</span>
              <v-select
                v-model="state.filters.dateRangeSelected"
                :items="referralLists.DATE_RANGE"
                @change="actions.applyDateFilter($event)"
                prepend-inner-icon="mdi-cursor-pointer"
                solo
                chips
                dense
                flat
                solo-inverted
                hide-details
              >
                <template v-slot:selection="data">
                  <v-chip small label v-bind="data.attrs">
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-select>
            </div>
            <div>
              <span class="font-weight-bold">{{ $t('referral.totals') }}</span>
              <v-divider class="mb-3"></v-divider>
              <v-row>
                <v-col></v-col>
                <v-col>
                  <span class="font-weight-bold">{{
                    $t('referral.fields.amount').toUpperCase()
                  }}</span>
                  <div class="primary--text font-weight-bold">
                    {{
                      state.currencySymbol + parseFloat(state.commissionTotals.amount).toFixed(2)
                    }}
                  </div>
                </v-col>
                <v-col>
                  <span class="font-weight-bold">{{
                    $t('referral.fields.referredAffiliates').toUpperCase()
                  }}</span>
                  <div class="primary--text font-weight-bold">
                    {{ state.commissionTotals.referredAffiliates }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <data-table
              :headers="headers2"
              :items="state.referralCommissions"
              :loading="state.loading"
              :server-items-length="state.total2"
              :options.sync="state.pagination2"
              @paginate="actions.applyOptions2($event)"
            >
              <template v-slot:item._referredAffiliate="{ item }">
                {{
                  getDescription(item).replace('[REFERRED_AFFILIATE_ID]', item._referredAffiliate)
                }}
              </template>
              <template v-slot:item.amount="{ item }">
                <v-chip label small color="primary">{{
                  state.currencySymbol + item.amount.toFixed(2)
                }}</v-chip>
              </template>
            </data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { inject, onMounted } from '@vue/composition-api';
import DataTable from '../Components/DataTable';
import referralLists from '../../store/constants/ReferralLists';

export default {
  name: 'referral',
  components: {
    DataTable,
  },
  setup(props, context) {
    const application = inject('Application');
    const referral = inject('Referral');
    onMounted(async () => {
      referral.state._affiliate = application.state.user._id;
    });
    const headers1 = [
      {
        text: context.root.$t('referral.fields._referredAffiliate'),
        aling: 'center',
        value: '_referredAffiliate',
        sortable: true,
        width: 200,
      },
      {
        text: context.root.$t('referral.fields.status'),
        aling: 'center',
        value: 'status',
        sortable: true,
        width: 200,
      },
      {
        text: context.root.$t('referral.fields.commission'),
        aling: 'center',
        value: 'commission',
        sortable: true,
        width: 200,
      },
      {
        text: context.root.$t('referral.fields.commisionPeriod'),
        aling: 'center',
        value: 'commisionPeriod',
        sortable: true,
        width: 200,
      },
      {
        text: context.root.$t('referral.fields.createdAt'),
        aling: 'center',
        value: 'createdAt',
        sortable: true,
        width: 200,
      },
    ];
    const headers2 = [
      {
        text: context.root.$t('referral.fields.description'),
        aling: 'center',
        value: '_referredAffiliate',
        sortable: true,
        width: 800,
      },
      {
        text: context.root.$t('referral.fields.amount'),
        aling: 'center',
        value: 'amount',
        sortable: true,
        width: 200,
      },
    ];
    const getDescription = ({ type, _referredAffiliate }) => {
      if (type === 'COMMISSION') {
        return context.root.$t('referral.fields.types.COMMISSION');
      }
      const affiliateId = application.state.user._id;
      if (affiliateId === _referredAffiliate) {
        return context.root.$t('referral.fields.types.SIGN_UP_BONUS_REFERRED');
      }
      return context.root.$t('referral.fields.types.SIGN_UP_BONUS_REFERRER');
    };
    return { application, headers1, headers2, getDescription, referralLists, ...referral };
  },
  computed: {
    getReferralUrl() {
      return 'https://toroadvertising.com/affiliates/signup/' + this.application.state.user._id;
    },
  },
};
</script>
