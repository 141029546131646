<template>
  <v-data-table
    :headers="headers"
    v-bind:items="items"
    v-bind:loading="loading"
    :options.sync="pagination"
    :server-items-length="serverItemsLength"
    :footer-props="{
      itemsPerPageOptions: itemsPerPageOptions,
      showFirstLastPage: true,
    }"
    must-sort
  >
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"
      ><slot :name="name" v-bind="slotData"
    /></template>
    <template v-slot:loading>
      <v-row class="fill-height" align="center" justify="center">
        <v-progress-circular indeterminate width="1" size="40" color="grey"></v-progress-circular>
      </v-row>
    </template>
  </v-data-table>
</template>
<script>
import { debounce } from 'lodash';

export default {
  name: 'data-table',
  data: () => ({
    pagination: {
      itemsPerPage: 20,
    },
    isFirstLoad: true,
  }),
  computed: {
    debouncedPaginate() {
      return debounce(() => {
        const { page, itemsPerPage, sortBy, sortDesc } = this.pagination;
        this.paginate({
          sortBy: sortBy.length === 1 ? sortBy[0] : null,
          descending: sortDesc.length === 1 ? sortDesc[0] : null,
          page: page - 1,
          rowsPerPage: itemsPerPage,
        });
      }, 300);
    },
  },
  props: {
    headers: Array,
    itemKey: {
      type: String,
      default: 'id',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [20, 30, 40, 50],
    },
    serverItemsLength: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    itemsNew: {
      type: Number,
    },
    sortBy: String | undefined,
    sortDesc: Boolean | undefined,
  },
  methods: {
    paginate(args) {
      this.$emit('paginate', args);
    },
    resetPage() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
        return true;
      }
      return false;
    },
  },
  watch: {
    pagination: {
      handler() {
        if (this.isFirstLoad) {
          if (this.sortBy) {
            this.pagination.sortBy = [this.sortBy];
          }
          if (this.sortDesc !== undefined) {
            this.pagination.sortDesc = [this.sortDesc];
          }
          this.isFirstLoad = false;
        }

        this.debouncedPaginate();
      },
      deep: true,
    },
  },
};
</script>
